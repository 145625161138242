@mixin for-phone {
  @media (max-width: 599px) { @content; }
}

.modal {
  background-color: rgba(49, 54, 76, 0.65);
  padding-right: 100px;
  padding-left: 100px;
  @include for-phone {
    padding-right: 0;
    padding-left: 0;
  }
}

.modalDialog {
  border-radius: 8px !important;
  box-shadow: 1px 1px 15px 0 rgba(120, 126, 156, 0.2);
  background-color: #ffffff;
  margin: 30px auto !important;
  max-width: 1146px !important;
  @include for-phone {
    max-width: 310px !important;
  }
  .modalContent {
    border-radius: 8px !important;

    .modalBody {
      overflow: scroll;
      max-width: 100%;
      padding: 0 20px 40px;
      max-height: calc(93vh - 60px);
      span {
        color: black !important;
        cursor: default !important;
      }
    }
  }
}
