.enabled {
    background-color: #fff !important;
    opacity: 1;
}

.invalidDisabled {
    color: #31364c !important;
}

// to suppress invalid indication for input tag
input.invalidDisabled:invalid {
    background-image: linear-gradient(to top, #07c0ca 2px, rgba(7, 192, 202, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}

.rateInfo {
    font-size: 14px;
    font-weight: bold;
    color: #8d93b2;
    span {
        font-size: 16px;
        margin-left: 6px;
        color: #07c0ca;
        position: relative;
        top: 3px;
        cursor: pointer;                            
    }
}

.clickable {
    color: #07c0ca;
    cursor: pointer;                            
}

.amount {
    font-size: 36px !important;
    font-weight: bold !important;
    text-align: center !important;
    height: 50px !important;
    // to suppress invalid indication for input tag
    input.invalidDisabled:invalid {
        background-image: linear-gradient(to top, #07c0ca 2px, rgba(7, 192, 202, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px) !important;
    }
}

.amountLabel {
    line-height: 8px !important;
    top: 0.01rem !important;
}

.invalidAmount {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

.noTricks {
    text-align: center;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 15px;
    font-size: 14px;
    color: #787e9c;
}

.plans {
    padding-bottom: 15px;
    >div {
        margin: 0px -15px;
    }
}

.interested {
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
    color: #abafcd;
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: -0.34px;    
}

.bottom {
    margin: 20px;
    text-align: center;
    color: #787e9c;
    font-size: 11px;
    letter-spacing: -0.24px;
    line-height: normal;
}

@keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
}

.hint {
    text-align: center;
    margin-top: 25px;
}
