.button {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    background-color: #ffffff !important;
    text-align: center;
    cursor: pointer;
    color: #07c0ca !important;
}

div.button:hover {
    color: #006f7b !important;
}

.message {
    font-size: 14px;
    line-height: 1.64;
    letter-spacing: -0.3px;
    text-align: center;
    color: #787e9c;
    padding: 15px;
}

.buttonLink {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    background-color: #ffffff !important;
    text-align: center;
    cursor: pointer;
    color: #07c0ca !important;
}
