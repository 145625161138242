.title {
  font-weight: 500;
  letter-spacing: -0.2px;
  color: #31364c;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.content {
  margin: 15px;

  p {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.57;
    letter-spacing: -0.34px;
    text-align: center;
    color: #787e9c;
    margin-bottom: 24px;

    span {
      color: #07c0ca;
    }
  }
}

.message {
  letter-spacing: normal;
}