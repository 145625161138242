.close {
    font-size: 32px;
}

.modal {
    background-color: rgba(49, 54, 76, 0.65);
}

.logo {
    display: block;
    width: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -15px;
    margin-bottom: 30px;
}
  
.modalDialog {
    border-radius: 8px !important;
    box-shadow: 1px 1px 15px 0 rgba(120, 126, 156, 0.2);
    background-color: #ffffff;
    max-width: 325px !important;
    margin: 100px auto !important;

    .modalContent {
        border-radius: 8px !important;

        .modalHeader {
            border-bottom: none;
            padding: 24px 20px 0 60px;

            button {
                margin-left: 0px !important;
            }
        }

        .modalBody {
            overflow: scroll;
            max-height: 65vh;
            max-width: 100%;
            padding-bottom: 40px;
            padding-top: 15px;
            h4 {
                font-size: 18px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: 0.27px;
                text-align: center;
                color: #31364c;
            }
            div {
                margin-top: 15px;
                padding-left: 10px;
                padding-right: 10px;
                text-align: left;
                font-size: 12px;
                color: #787e9c;
                p {
                    font-size: 12px !important;
                    font-weight: 400 !important;
                    margin-bottom: 0;
                }
            }
        }
    }
}