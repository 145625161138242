.image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    margin-bottom: 30px;
}

.content {
    margin: 15px;
  
    p {
      font-size: 14px;
      font-weight: normal;
      line-height: 1.57;
      letter-spacing: -0.34px;
      text-align: left;
      color: #787e9c;
      margin-bottom: 24px;
  
      span {
        color: #07c0ca;
        cursor: pointer;
      }
    }
  }

.button {
    border-radius: 2px !important;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 18px !important;
    padding-bottom: 18px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    width: 100% !important;
    margin-top: 7px !important;
    margin-bottom: 7px !important;
}

.buttonEnabled {
    @extend .button;
    background-color: #07c0ca !important;
}
  
button.buttonEnabled:hover {
    background-color: #006f7b !important;
    box-shadow: none !important;
}

.buttonDisabled {
  @extend .button;
  background-color: #d6d8e1 !important;
}

.buttonEnabledInverse {
  @extend .button;
  background-color: #FFFFFF !important;
  color: #07c0ca !important;
  box-shadow: none !important;
}

button.buttonEnabledInverse:hover {
  background-color: #FFFFFF !important;
  color: #07c0ca !important;
  box-shadow: none !important;
}

.message {
  font-size: 14px;
  line-height: 1.64;
  letter-spacing: -0.3px;
  text-align: center;
  color: #787e9c;
  padding: 15px;
}