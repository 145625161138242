.lock {
  display: block;
  margin: 30px auto;
  height: 64px;
}

.message {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #52576F;
  margin-bottom: 20px;
}
