.subtitle {
  text-align: center;
}

.options {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  text-align: left;
  color: #6f7595;
  margin-bottom: 25px;
}
