.image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 30px;
}

.content {
  margin: 15px;

  p {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.57;
    letter-spacing: -0.34px;
    text-align: center;
    color: #787e9c;
    margin-bottom: 24px;

    div {
      color: #07c0ca;
      cursor: pointer;
    }

    span {
      text-align: center;
      display: flex;
    }

  }
}

.button {
  border-radius: 2px !important;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 18px !important;
  padding-bottom: 18px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  width: 100% !important;
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}

.buttonEnabled {
  @extend .button;
  background-color: #07c0ca !important;
}

button.buttonEnabled:hover {
  background-color: #006f7b !important;
  box-shadow: none !important;
}

.buttonDisabled {
  @extend .button;
  background-color: #ACE9EB !important;
}

.buttonEnabledInverse {
  @extend .button;
  background-color: #FFFFFF !important;
  color: #07c0ca !important;
  box-shadow: none !important;
}

button.buttonEnabledInverse:hover {
  background-color: #FFFFFF !important;
  color: #07c0ca !important;
  box-shadow: none !important;
}

.message {
  font-size: 14px;
  line-height: 1.64;
  letter-spacing: -0.3px;
  text-align: center;
  color: #787e9c;
  padding: 15px;
}

.verifyAgainInfoSquare {
  background: #FFFFFF;
  border: 1px solid #07C0CA;
  box-sizing: border-box;
  border-radius: 2px;
  overflow: auto;
  margin-top: 30px;

  .verifyAgainInfoIcon {
    float: left;
    margin: 25px 15px 25px 25px;

    img {
      display: block;
    }
  }

  .verifyAgainInfoText {
    margin: 25px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    color: #52576F;
  }
}

.couldNotVerifyWarnSquare {
  background: #FFFFFF;
  border: 1px solid #FE6E65;
  box-sizing: border-box;
  border-radius: 2px;
  overflow: auto;
  margin-top: 30px;

  .couldNotVerifyWarnIcon {
    float: left;
    margin: 25px 15px 25px 25px;

    img {
      display: block;
    }
  }

  .couldNotVerifyWarnText {
    margin: 25px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    color: #FE6E65;
  }
  b {
    color: #52576F;
  }

}