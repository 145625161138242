.message {
  font-size: 14px;
  line-height: 1.64;
  letter-spacing: -0.3px;
  text-align: center;
  color: #787e9c;
  padding: 15px;
}

.title {
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: center;
  color: #31364c;
  margin-top: 25px;
}

.content {
  margin: 15px;

  p {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.57;
    letter-spacing: -0.34px;
    text-align: center;
    color: #787e9c;
    margin-bottom: 24px;

    div {
      color: #07c0ca;
      cursor: pointer;
    }

    span {
      text-align: center;
      display: flex;
    }

  }
}

.button {
  border-radius: 2px !important;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 18px !important;
  padding-bottom: 18px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  width: 100% !important;
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}

.buttonEnabled {
  @extend .button;
  background-color: #07c0ca !important;
}

button.buttonEnabled:hover {
  background-color: #006f7b !important;
  box-shadow: none !important;
}

.buttonDisabled {
  @extend .button;
  background-color: #ACE9EB !important;
}

.buttonEnabledInverse {
  @extend .button;
  background-color: #FFFFFF !important;
  color: #07c0ca !important;
  box-shadow: none !important;
}

button.buttonEnabledInverse:hover {
  background-color: #FFFFFF !important;
  color: #07c0ca !important;
  box-shadow: none !important;
}

.firstMonthPayment {
  border-radius: 8px;
  background-color: #ffffff;
  min-height: 65px;
  margin: 15px 15px 18px 15px;
  box-shadow: 1px 1px 15px 0 rgba(120, 126, 156, 0.2);
  border: solid 1.3px #ffffff;
  padding-left: 10px;
  padding-right: 10px;

  .title {
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.24px;
    color: #31364c;
    text-transform: uppercase;
  }
}