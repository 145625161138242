@mixin for-phone {
  @media (max-width: 599px) { @content; }
}

.content {
  padding-left: 10px;
  padding-right: 10px;
  @include for-phone {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.header {
  .logo {
    display: block;
    margin: 50px auto;
    height: 25px;
  }
}

.modalDialog {
  max-width: 1146px !important;
  border-radius: 8px !important;
  background-color: #ffffff;
  margin: 30px auto !important;
  .modalContent {
    box-shadow: 1px 1px 15px 0 rgba(120, 126, 156, 0.2) !important;
    border-radius: 8px !important;
    .modalBody {
      max-width: 100%;
      padding-top: 30px;
      padding-left: 80px;
      padding-right: 80px;
      @include for-phone {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}


