.m1 {
  margin-top: 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #52576F;
}

.l1 {
  margin-top: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #07C0CA;
  cursor: pointer;
}