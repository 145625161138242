.prompt {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 12px;
  color: #07C0CA;
  margin-top: 20px;
  cursor: pointer;
}

.amountGroup {
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 15px;
}

.amountTitle {
  margin-top: 30px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #52576F;
}

.errorMessage {
  margin-top: 30px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: red;
}

.amountInput {
  margin-right: 15px;
  padding-top: 5px;
  border: 1.5px solid #D6D8E1;
  border-radius: 8px;
  text-align: center;
  // disable input underline
  input {
    background-image: none !important;
  }
}

.enabled {
  background-color: #fff !important;
  opacity: 1;
}

.invalidDisabled {
  color: #31364c !important;
}

.amount {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
  height: 28px !important;
}

.amountLabel {
  line-height: 8px !important;
  top: 0.01rem !important;
}

.terms {
  font-style: normal;
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: normal;
  font-size: 10px;
  line-height: 140%;
  text-align: center;
  color: #52576F;
}

.button {
  border-radius: 8px !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  margin: 1px 0 !important;
  max-width: 115px !important;
}

.buttonDisabled {
  @extend .button;
  background-color: #d6d8e1 !important;
}

.buttonEnabled {
  @extend .button;
  background-color: #07c0ca !important;
}

button.buttonEnabled:hover {
  background-color: #006f7b !important;
  box-shadow: none !important;
}

.wrapper {
  margin-left: 15px;
  margin-right: 15px;
}

.details {
  text-align: center;
  border-radius: 8px;
  background-color: #ffffff;
  min-height: 100px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  box-shadow: 1px 1px 15px 0 rgba(120, 126, 156, 0.2);
  border: solid 1.3px #ffffff;

  .title {
    font-size: 16px;
    font-style: normal;
    font-stretch: normal;
    text-align: center;
    color: #31364c;
    margin-top: 25px;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.27px;
  }
  .subtitle {
    text-align: center;
    font-size: 36px;
    color: #31364C;
    margin-top: 10px;
    margin-bottom: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -0.868235px;
  }
  .separator {
    border-bottom: 2px solid #D6D8E1;
    box-sizing: border-box;
  }
  .additional {
    margin-top: 16px;
    display: grid;
    line-height: 140%;
    font-style: normal;
    grid-template-columns: 1fr 1fr;
    color: #52576F;

    .key {
      font-weight: 400;
      font-size: 10px;
      text-align: left;
    }
    .value {
      font-weight: 600;
      font-size: 12px;
      text-align: right;
    }
  }
}

.plans {
  margin-top: 30px;
  margin-left: -15px;
  margin-right: -15px;
}
