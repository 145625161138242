.message {
  font-size: 14px;
  margin-bottom: 10px;
  text-align: left;
  span {
    color: #07c0ca;
    cursor: pointer;
  }
}

.button {
  border-radius: 2px !important;
  padding-top: 18px !important;
  padding-bottom: 18px !important;
  font-size: 14px !important;
  font-weight: bold !important;
}

.buttonDisabled {
  @extend .button;
  background-color: #d6d8e1 !important;
}

.buttonEnabled {
  @extend .button;
  background-color: #07c0ca !important;
}

button.buttonEnabled:hover {
  background-color: #006f7b !important;
  box-shadow: none !important;
}
