input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.datePicker {
    display: flex;
    flex-direction: row;
}

div.datePicker {
    // to suppress invalid indication for input tag
    input:invalid {
        background-image: linear-gradient(to top, #07c0ca 2px, rgba(7, 192, 202, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
    }
    // month
    input:first-child {
        text-align: right;
        min-width: 36px;
        width: 36px; 
        padding-right: 5px;
    }
    // day
    //input:nth-child(3) {
    //    text-align: center;
    //    min-width: 32px;
    //    width: 32px;
    //}
    // year
    input:nth-child(3) {
        padding-left: 5px
    }
    span {
        font-size: 18px;
        position: relative;
        bottom: -8px;       
    }
}

.fieldError {
    color: #f44336;
    font-size: 0.78rem;
    line-height: 0.1;
}

