.plan {
  border-radius: 8px;
  background-color: #ffffff;
  min-height: 100px;
  margin: 15px;
  box-shadow: 1px 1px 15px 0 rgba(120, 126, 156, 0.2);
  border: solid 1.3px #ffffff;
}

div.plan:hover {
  border-color: #07c0ca;
  cursor: pointer;
}

.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 15px;
}

.months {
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #787e9c;
  text-align: center;
  margin-bottom: 5px;
}

.amount {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #31364c;
  text-align: center;
  margin-bottom: 15px;
}

.free {
  width: 105px;
  height: 20px;
  border-radius: 10px;
  background-color: #ffae1a;
  font-size: 10px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  line-height: 2;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 18px;
}

.total {
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.8;
  letter-spacing: -0.2px;
  text-align: center;
  color: #787e9c;
  margin-bottom: 15px;
}