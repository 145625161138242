.bar {
    border-radius: 8px;
    margin-top: 10px;
    box-shadow: 1px 1px 15px 0 rgba(120, 126, 156, 0.2);
    table {
        width: 100%;
        td {
            width: 17.5%;
            padding: 0px;
            &:first-child {
                width: 30%;
            }
        }
    }
}