.subtitle {
  text-align: center;
}

.info {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 200%;
  color: #52576F;
}

.message {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #52576F;
  margin-top: 15px;
}

.warnSquare {
  background: #FFFFFF;
  border: 1px solid #FE6E65;
  box-sizing: border-box;
  border-radius: 2px;
  overflow: auto;
  margin-bottom: 30px;

  .warnIcon {
    float: left;
    margin: 24px 15px 24px 24px;

    img {
      display: block;
    }
  }

  .warnText {
    margin: 25px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    color: #FE6E65;
  }
}

