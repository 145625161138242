.box {
  width: 28px;
  height: 28px;
  border-radius: 5px;
  border: solid 1.5px #07c0ca;
  text-align: center;
}

div.box:hover {
  border: solid 1.5px #08d2dd;
  box-shadow: 1px 1px 15px 0 rgba(120, 126, 156, 0.2);
}

.done {
  padding-top: 2px;
  font-size: 20px;
  color: #07C0CA;
}