.modal {
  background-color: rgba(49, 54, 76, 0.65);
}

.modalDialog {
  border-radius: 8px !important;
  box-shadow: 1px 1px 15px 0 rgba(120, 126, 156, 0.2);
  background-color: #ffffff;
  max-width: 325px !important;
  margin: 100px auto !important;

  .modalContent {
    border-radius: 8px !important;
    .modalHeader {
      border-bottom: none;
      padding: 24px 20px 0 60px;
      button {
        margin-left: 0 !important;
      }
      img {
        width: 96px;
        height: 44px;
        margin: 6px auto 0 auto;
      }
      :hover {
        background-color: white !important;
      }
    }
    .modalBody {
      text-align: center;
      max-height: 65vh;
      max-width: 100%;
      padding-bottom: 40px;
      padding-top: 15px;
      h1 {
        font-size: 18px;
        line-height: 140%;
        font-weight: bold;
      }
      p {
        font-weight: normal;
        font-size: 12px;
        line-height: 140%;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        color: #52576F;
        margin-bottom: 15px;
      }
    }
  }
}
