.image {
    display: block;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    min-width: 78px;
}

.div {
    text-align: center;
    color: #52576F;
    margin-bottom: 15px;
}