.details {
  border-radius: 8px;
  background-color: #ffffff;
  min-height: 100px;
  margin: 15px;
  box-shadow: 1px 1px 15px 0 rgba(120, 126, 156, 0.2);
  border: solid 1.3px #ffffff;
  padding: 5px 20px 20px;
}

.name {
  margin-top: 15px;
  font-weight: 600;
  font-size: 10px;
  line-height: 140%;
  color: #52576F;
}

.icon {
  font-size: 16px;
  padding-left: 5px;
  vertical-align: -4px;
}

.exp {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  color: #52576F;
}

.nav {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.mint {
  color: #07C0CA;
  cursor: pointer;
}

.grey {
  color: #D6D8E1;
  cursor: default;
}

.black {
  color: #52576F;
  cursor: pointer;
}


.amount {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
}

.delim {
  font-size: 12px;
}

.status {
  padding-top: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
}

.open_icon {
  padding-top: 3px;
  font-size: 16px;
}