.plan {
  margin: 30px 15px;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: #52576F;
  background: #F9F9F9;
  border-radius: 8px;
  padding: 15px;
}

.total {
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  color: #52576F;
  margin: 15px;
}

.message {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #52576F;
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
}

.learnMore {
  cursor: pointer;
  b {
    color: #00c3cc;
  }
}