.logo {
  display: block;
  margin: 30px auto 30px;
}

.message {
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #52576F;
  margin-bottom: 15px;
}

.buttonLink {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  background-color: #ffffff !important;
  text-align: center;
  cursor: pointer;
  color: #07c0ca !important;
}
