.modal {
  background-color: rgba(49, 54, 76, 0.65);
}

.modalDialog {
  border-radius: 8px !important;
  max-width: 325px !important;
  margin: 64px auto !important;

  .modalContent {
    padding: 4px;
    border-radius: 8px !important;

    .modalHeader {
      border-bottom: none;
      padding: 20px 20px 12px 20px;

      .modalTitle {
        margin-bottom: 0;
        line-height: 1.5;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.24px;
        color: #31364c;
        text-transform: uppercase;
        padding-top: 0;
        margin-top: 2px;
      }
    }

    .modalBody {
      overflow: scroll;
      max-height: 62vh;
      max-width: 100%;
    }

    .modalFooter {
      padding-top: 12px !important;
      flex-direction: column;

      .bottom {
        align-self: center;
      }

    }
  }
}

.image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 10px;
  width: 62px;
  height: 40px;
}

.button {
  border-radius: 2px !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  margin: 0px !important;
}

.buttonDisabled {
  @extend .button;
  background-color: #d6d8e1 !important;
}

.buttonEnabled {
  @extend .button;
  background-color: #07c0ca !important;
}

.link {
  span {
    color: #00c3cc;
    cursor: pointer;
  }

  span:hover {
    color: #006f7b !important;
  }
}
