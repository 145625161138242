.review {
  border-radius: 8px;
  background-color: #ffffff;
  min-height: 100px;
  margin: 15px;
  box-shadow: 1px 1px 15px 0 rgba(120, 126, 156, 0.2);
  border: solid 1.3px #ffffff;
  padding: 5px 20px 20px;
}

.image {
  display: block;
  margin: 24px auto;
}

.name {
  font-weight: 600;
  font-size: 10px;
  color: #31364C;
  text-transform: uppercase;
}

.label {
  font-style: normal;
  font-family: Montserrat, serif;
  position: relative;
  font-weight: 400;
  font-size: 70%;
  line-height: 8px;
  color: #52576F;
}

.value {
  margin-left: 5px;
  font-family: Montserrat, serif;
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 70%;
  line-height: 8px;
  color: #52576F;
}

.aap {
  margin-top: 15px;
  border-top: 1.3px solid #D6D8E1;
  padding-top: 15px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.annual {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 140%;
  color: #31364C;
}

.apr {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 140%;
  color: #31364C;
}