.message {
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #52576F;
  margin-bottom: 15px;
}

.phone {
  color: #07c0ca;
}