.enabled {
    background-color: #fff !important;
    opacity: 1;
  }
.invalidDisabled {
    color: #31364c !important;
}

// to suppress invalid indication for input tag
div.invalidDisabled:invalid {
    background-image: linear-gradient(to top, #07c0ca 2px, rgba(7, 192, 202, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}

div::-webkit-outer-spin-button,
div::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

div::-webkit-outer-spin-button,
div::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

div {
    -moz-appearance:textfield;
}

.fieldError {
    color: #f44336;
    font-size: 0.78rem;
    line-height: 140%;
}

.fieldSuggestion {
    color: #f44336;
    font-size: 0.78rem;
    font-weight: bold;
    line-height: 0.1;
}

.textarea {
    height: 75px !important;
    line-height: normal;
}

.hintLabel {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    margin-top: 8px;
    color: #07C0CA;
}

.backgroundOverride {
    background-image: linear-gradient(to top, #07c0ca 2px, rgba(7, 192, 202, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px) !important;
}
.microFormLabel {
    position: absolute;
    top: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: #6f7595;
    transition: top .2s ease-in;
    z-index: 10;
    pointer-events: none;
}
.microFormLabelTop {
    top: 0px;
}
