.truth {
  border-radius: 8px;
  background-color: #ffffff;
  min-height: 100px;
  margin: 15px 15px 18px 15px;
  box-shadow: 1px 1px 15px 0 rgba(120, 126, 156, 0.2);
  border: solid 1.3px #ffffff;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}

.image {
  display: block;
  margin: 24px auto;
}

.title {
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.24px;
  color: #31364c;
  text-transform: uppercase;
}

.titleSm {
  font-size: 10px;
}

.annualPercentageText {
  padding-left: 15px;
}

.annualPercentageValue {
  padding-left: 15px;
}

.annualPercentageTextLabel {
  line-height: 1.8;
  font-size: 12px;
  margin-top: 5px;
  color: #787e9c;
}

.items {
  margin-top: 5px;

  .item {
    font-style: normal;
    font-family: Montserrat, serif;
    position: relative;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.6;
    color: #52576F;

    span {
      margin-left: 5px;
      font-family: Montserrat, serif;
      position: relative;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 1.6;
      color: #52576F;
    }
  }
}


.line {
  height: 1px;
  border: solid 0.5px #d6d8e1;
  margin: 15px 0;
}

.approve {
  font-size: 11px;
  // margin-bottom: 15px;
  margin-left: 15px;
  margin-right: 15px;
  letter-spacing: -0.2px;
  color: #787e9c;

  .reviewed {

    span {
      color: #00c3cc;
      cursor: pointer;
    }

    span:hover {
      color: #006f7b !important;
    }
  }
}

.ssn {
  width: 30px;
  height: 21px;
  margin: 18px 8px 0 6px;
}

.box {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border: solid 1.5px #07c0ca;
}

div.box:hover {
  border: solid 1.5px #08d2dd;
  box-shadow: 1px 1px 15px 0 rgba(120, 126, 156, 0.2);
}

.button {
  border-radius: 2px !important;
  padding-top: 18px !important;
  padding-bottom: 18px !important;
  font-size: 14px !important;
  font-weight: bold !important;
}

.buttonDisabled {
  @extend .button;
  background-color: #d6d8e1 !important;
}

.buttonEnabled {
  @extend .button;
  background-color: #07c0ca !important;
}

button.buttonEnabled:hover {
  background-color: #006f7b !important;
  box-shadow: none !important;
}


.buttonBack {
  font-size: 14px !important;
  font-weight: bold !important;
  border-radius: 2px;
  border: 1.5px solid #07C0CA;
  box-sizing: border-box;
  height: 54px;
  line-height: 54px;

  background-color: #ffffff !important;
  text-align: center;
  cursor: pointer;
  color: #07c0ca !important;
  margin: 15px auto;
}

div.buttonBack:hover {
  color: #006f7b !important;
}

.message {
  margin-top: 15px;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: #52576F;
  display: flex;
  justify-content: center;
}

.next_icon {
  padding-left: 5px;
  font-size: 16px;
}
