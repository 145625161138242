.image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 30px;
}

.message {
  font-size: 14px;
  line-height: 1.64;
  letter-spacing: -0.3px;
  text-align: center;
  color: #787e9c;
  padding: 15px;
}

.text {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.64;
  letter-spacing: -0.3px;
  text-align: center;
  color: #787e9c;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 30px;

  span {
    font-weight: 600;
    color: #07c0ca;
    cursor: pointer;
  }
}

.couldNotLinkBankWarnSquare {
  background: #FFFFFF;
  border: 1px solid #FE6E65;
  box-sizing: border-box;
  border-radius: 2px;
  overflow: auto;
  margin-bottom: 30px;

  .couldNotLinkBankWarnIcon {
    float: left;
    margin: 24px 15px 24px 24px;

    img {
      display: block;
    }
  }

  .couldNotLinkBankWarnText {
    margin: 25px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    color: #FE6E65;
  }
}

.automatedPaymentsWarnSquare {
  background: #FFFFFF;
  border: 1px solid #FE6E65;
  box-sizing: border-box;
  border-radius: 2px;
  overflow: auto;
  margin-bottom: 30px;

  .automatedPaymentsWarnIcon {
    float: left;
    margin: 24px 15px 24px 24px;

    img {
      display: block;
    }
  }

  .automatedPaymentsWarnText {
    margin: 25px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    color: #FE6E65;
  }
}