.message {
    font-size: 14px;
    line-height: 1.64;
    letter-spacing: -0.3px;
    text-align: center;
    color: #787e9c;
    padding: 15px;
}

.title {
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
    color: #31364c;
    margin-top: 25px;
}

.infoIcon {
    font-size: 16px;
    padding-left: 2px;
    vertical-align: middle;
    visibility: visible;
    color: #0aced9
}

.hideInfoIcon {
    visibility: hidden;
}

.modal_text {
    text-align: center;
    font-weight: normal;

    .logo {
        margin: -15px auto 20px;
    }

    div {
        margin-top: 15px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 14px;
        color: #52576F;
        line-height: 140%;
        span {
            color: #0aced9;
        }
    }
}