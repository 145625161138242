.error {
  display: grid;
  grid-template-columns: 36px 235px;
  background: #FFFFFF;
  border: 1.3px solid #FE6E65;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 22px 20px 22px;

  img {
    grid-column-start: 1;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .message {
    margin: auto;
    overflow: auto;
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 1;
    line-height: 140%;
    font-size: 12px;
    color: #FE6E65;
    display: inline;
    padding-left: 15px;
  }
}