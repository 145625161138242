.flatpickr-input:read-only {
  cursor: text;
  background-image:
          linear-gradient(to top, #07c0ca 2px, rgba(7, 192, 202, 0) 2px),
          linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px) !important;
}

.flatpickr-day.selected {
  background: #00c6ce;
  border-color: #00c6ce;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #00c6ce;
}

.flatpickr-weekdays {
  background: #00c6ce;
}

.flatpickr-months .flatpickr-month {
  background: #00c6ce;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: #00c6ce;
  -webkit-appearance: none;
}

span.flatpickr-weekday {
  background: #00c6ce;
}

.flatpickr-day.selected:hover{
  background: #00c6ce;
  border-color: #00c6ce;
}