.message {
  margin-top: 15px;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: #52576F;
  display: flex;
  justify-content: center;
}

.next_icon {
  padding-left: 5px;
  font-size: 16px;
}

.approve {
  font-weight: 400;
  font-size: 11px;
  line-height: 140%;
  margin-left: 15px;
  margin-right: 15px;
  color: #52576F;
  .reviewed {
    span {
      color: #00c3cc;
      cursor: pointer;
    }
    span:hover {
      color: #006f7b !important;
    }
  }
}

.header {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #52576F;
  padding-top: 16px;
}

.button {
  border-radius: 2px !important;
  padding-top: 18px !important;
  padding-bottom: 18px !important;
  font-size: 14px !important;
  font-weight: bold !important;
}

.buttonDisabled {
  @extend .button;
  background-color: #d6d8e1 !important;
}

.buttonEnabled {
  @extend .button;
  background-color: #07c0ca !important;
}

button.buttonEnabled:hover {
  background-color: #006f7b !important;
  box-shadow: none !important;
}

.buttonContainer {
  padding-left: 15px;
  padding-right: 15px;
}

.lock {
  font-size: 16px;
  margin-right: 5px;
  padding-bottom: 3px;
}
