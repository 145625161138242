.button {
    box-shadow: none;

    font-size: 14px;
    font-weight: bold;
}

#primaryButton {
    @extend .button;
    background-color: #07c0ca;
    border-radius: 2px;
    line-height: 30px;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 15px;
}

#primaryButton:hover,
#primaryButton:active {
    background-color: #006f7b;
}

.primaryDisabled {
    background-color: #d6d8e1 !important;
}

#secondaryButton {
    @extend .button;
    background-color: white;
    border: 1.5px solid #07c0ca;
    border-radius: 2px;

    color: #07c0ca;

    line-height: 28px;
}

#secondaryButton:hover,
#secondaryButton:active {
    color: #006f7b;
    border-color: #006f7b;
}

.secondaryDisabled {
    color: #d6d8e1 !important;
    border-color: #d6d8e1 !important;
}

#textButton {
    @extend .button;
    line-height: 17px;
    color: #07c0ca;
    background-color: inherit;
    padding: 0;
    margin-bottom: 0;
}

#textButton:hover,
#textButton:active {
    color: #006f7b;
}

.textDisabled {
    color: #d6d8e1 !important;
}

#linkButton {
    @extend .button;
    text-transform: inherit;
    line-height: 17px;
    color: #07c0ca;
    background-color: inherit;
    padding: 0;
    margin-bottom: 0;
}

#linkButton:hover,
#linkButton:active {
    color: #006f7b;
}

.linkDisabled {
    color: #d6d8e1 !important;
}
