.button {
    border-radius: 2px !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    margin: 0px !important;
}

.buttonDisabled {
    @extend .button;
    background-color: #d6d8e1 !important;
}

.buttonEnabled {
    @extend .button;
    background-color: #07c0ca !important;
}

button.buttonEnabled:hover {
    background-color: #006f7b !important;
    box-shadow: none !important;
}

div.button:hover {
    color: #006f7b !important;
}
