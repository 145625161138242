.warnSquare {
  background: #FFFFFF;
  border: 1.5px solid #FE6E65;
  box-sizing: border-box;
  border-radius: 2px;
  overflow: auto;

  .warnIcon {
    float: left;
    margin: 20px 15px 20px 20px;

    img {
      display: block;
    }
  }

  .warnText {
    padding: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    color: #FE6E65;
  }
}
