.subtitle {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  text-align: left;
  color: #6f7595;
  margin-bottom: 15px;

  a {
    font-weight: 600;
    color: #07c0ca;
  }

  span {
    font-weight: 600;
    color: #07c0ca;
    cursor: pointer;
  }
}

.title {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: center;
  color: #31364c;
  margin-bottom: 15px;
}

.progress {
  height: 4px;
  background-color: #ffe7dc;
}

.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  height: 20px;
}

.close {
  color: #07c0ca;
  margin-top: 22px;
  font-size: 48px;
  float: right;
  cursor: pointer;
}

.back {
  color: #07c0ca;
  margin-top: 22px;
  font-size: 48px;
  float: left;
  cursor: pointer;
}
