.expired {
  display: block;
  margin: 10px auto 30px;
  height: 64px;
}

.continue {
  border-radius: 2px !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  margin: 0px !important;
  margin-top: 15px !important;
  background-color: #07c0ca !important;
  &:hover {
    background-color: #006f7b !important;
    box-shadow: none !important;
  }
}

.message {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #52576F;
  margin-bottom: 20px;
}
