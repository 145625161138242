.close {
  font-size: 32px;
}

.modal {
  background-color: rgba(49, 54, 76, 0.65);
}

.modalDialog {
  border-radius: 8px !important;
  box-shadow: 1px 1px 15px 0 rgba(120, 126, 156, 0.2);
  background-color: #ffffff;
  max-width: 325px !important;
  margin: 100px auto !important;

  .modalContent {
    border-radius: 8px !important;

    .modalHeader {
      border-bottom: none;
      padding: 24px 20px 0 60px;

      button {
        margin-left: 0 !important;
      }
    }

    .modalBody {
      overflow: auto;
      max-height: 65vh;
      max-width: 100%;
      padding: 15px 30px 40px;
    }
  }
}