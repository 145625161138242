.rate {
    padding: 10px 0px !important;
    margin: 0px !important;
    border-radius: 0px !important;
    box-shadow: 0 0 0 !important;
    width: 100% !important;
    background-color: white !important;
    color: black !important;
}

.selected {
    background-color: #07c0ca !important;
    color: white !important;
}

.first {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
}

.last {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
}

.border:after {
    content : "";
    position: absolute;
    right    : 0px;
    z-index: 100;
    top  : 25%;
    width  : 1px;
    height   : 50%;
    background: #07c0ca;
}
