.inputs {
  // padding: 10px;

  input {
    font-size: 18px;
  }

  textarea {
    font-size: 18px;
  }
}