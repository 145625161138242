.loader {
  display: block;
  margin: 30px auto;
  height: 64px;
  padding-left: 5px;
}

.title {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: center;
  color: #31364c;
}

.animatedMessage {
  text-align: center;
  font-size: 14px;
}

.animatedMessage:before {
  content: "";
  animation: phraseChange 15s infinite;
}

@keyframes phraseChange {
  0% {
    content: "No hidden fees";
  }
  16.66% {
    content: "No hidden fees";
  }
  33.33% {
    content: "No impact to your credit";
  }
  49.99% {
    content: "Clear terms, no tricks";
  }
  66.66% {
    content: "100% paperless";
  }
  83.33% {
    content: "Customers love us";
  }
}