.plan {
  border-radius: 8px;
  background-color: #ffffff;
  min-height: 100px;
  margin: 15px;
  box-shadow: 1px 1px 15px 0 rgba(120, 126, 156, 0.2);
  border: solid 1.3px #ffffff;
}

div.plan:hover {
  border-color: #07c0ca;
  cursor: pointer;
}

.months {
  margin-top: 20px;
  margin-left: 20px;

  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;

  text-transform: uppercase;

  color: #07C0CA;
}

.amount {
  margin-top: 12px;
  margin-left: 20px;

  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;

  letter-spacing: 0.27px;

  color: #31364C;
}

.total {
  margin-top: 5px;
  margin-left: 20px;

  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;

  color: #52576F;
}

.dueAtStartText {
  margin-top: 15px;
  margin-left: 20px;

  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 140%;

  letter-spacing: -0.241176px;

  color: #52576F;
}

.dueAtStartAmount {
  margin-top: -14px;
  margin-right: 20px;

  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 140%;

  text-align: right;
  letter-spacing: -0.241176px;

  color: #52576F;
}

.dueMonthlyText {
  margin-top: 5px;
  margin-left: 20px;

  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 140%;

  letter-spacing: -0.241176px;

  color: #52576F;
}

.dueMonthlyAmount {
  margin-top: -14px;
  margin-right: 20px;
  margin-bottom: 20px;

  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 140%;

  text-align: right;
  letter-spacing: -0.241176px;

  color: #52576F;
}