@media print {
  .modalDialog {
    min-width: 95%;
  }
}

.title {
    font-size: 19px;
    font-weight: bold;
    letter-spacing: 0.3px;
    text-align: center;
    color: #31364c;
    margin-bottom: 18px;
    text-transform: uppercase;
  }

  .logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    height: 25px;
  }
  
  .smallFont {
    font-size: 13px;
  }
  
  .close {
    font-size: 32px;
  }
  
  .updated {
    font-weight: bold;
    font-size: 14px;
    color: #6F7595;
  }
  
  .modal {
    background-color: rgba(49, 54, 76, 0.65);
  }
  
  .modalDialog {
    max-width: 842px !important;
    border-radius: 8px !important;
    background-color: #ffffff;
    margin: 30px auto !important;
    // max-width: 325px !important;
  
    .modalContent {
      box-shadow: 1px 1px 15px 0 rgba(120, 126, 156, 0.2) !important;
      border-radius: 8px !important;
  
      .modalBody {
        //overflow: scroll;
        max-width: 100%;
        padding-bottom: 40px;
        padding-top: 25px;
        //max-height: calc(93vh - 60px);
  
        h4 {
          font-size: 19px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.27px;
          text-align: center;
          color: #31364c;
        }
  
        .paraTitle {
          font-size: 14px;
          font-weight: 600;
          line-height: 1.5;
          letter-spacing: normal;
          color: #31364c;
          margin-bottom: 8px;
          text-align: left;
          text-transform: uppercase;
        }

        .center {
          text-align: center;
        }

        .text {
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: #6F7595;
          margin-bottom: 15px;
          // word-break: break-all;
        }
  
        .table {
          font-size: 14px;
          color: #6F7595;
          font-weight: normal;
          line-height: 1.5;
          margin-bottom: 24px;
          width: 100%;
  
          .tableHeader {
            border-right: 1px solid #00c3cc;
  
            th {
              background-color: #00c3cc;
              color: #ffffff;
              text-transform: uppercase;
              padding: 8px;
              max-width: 90px;
              font-size: 14px;
              border-right: 1px solid #ffffff;
  
              &:first-child {
                padding-left: 16px;
              }
  
              &:last-child {
                // border-bottom: 1px solid #ffffff;
                padding-right: 16px;
                border-right: 1px solid #00c3cc;
              }
  
              &.middleCell {
                padding: 4px;
                font-size: 14px;
              }
            }
          }
  
          .tableBody {
            tr {
              border-bottom: 1px solid #00c3cc;
  
              td {
                padding: 16px;
                min-width: 70px;
                border-left: 1px solid #00c3cc;
  
                &:first-child {
                  // background-color: #00c3cc;
                  // color: #ffffff;
                  // text-transform: uppercase;
                  // border-bottom: 1px solid #ffffff;
                  // font-weight: 600;
                }
  
                &:last-child {
                  border-right: 1px solid #00c3cc;
                }
              }
            }
          }
        }
  
        ol,
        ul {
          padding-left: 24px;
  
          li {
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: #6F7595;
            padding-left: 12px;
            margin-bottom: 4px;
          }
        }
      }
    }
  }
  
  .wrapAll {
    word-break: break-all;
  }

.boxed {
  border: 2px solid black;
  padding: 15px;
}