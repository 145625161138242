  @keyframes scale-up {
    0%, 80%, 100% {
      -moz-transform: scale(0);
      -ms-transform: scale(0);
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @-webkit-keyframes scale-up {
    0%, 80%, 100% {
      -moz-transform: scale(0);
      -ms-transform: scale(0);
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  .loader {
    background-color: transparent;
    text-align: center;
    margin: 0 auto;
    padding: 15px 20px;
  }
  .two {
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    background-color: #07c0ca;
    width: 24px;
    height: 24px;
    -webkit-animation: scale-up 1.4s infinite ease-in-out;
    animation: scale-up 1.4s infinite ease-in-out;
    display: inline-block;
  }
  .one {
    @extend .two;
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
    left: -30px;
  }
  .three {
    @extend .two;
    -webkit-animation-delay: 0.16s;
    animation-delay: 0.16s;
    left: 30px;
  }
  