.score {
    font-size: 13.5px;
    letter-spacing: -0.3px;
    text-align: left;
    color: #787e9c;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 15px;
  }

  .content {
    margin: 15px;
  
    p {
      font-size: 14px;
      font-weight: normal;
      line-height: 1.57;
      letter-spacing: -0.34px;
      text-align: left;
      color: #787e9c;
      margin-bottom: 24px;
  
      span {
        color: #07c0ca;
        cursor: pointer;
      }
    }
  }
  
  .scoreTxt {
    text-align: center !important;
    display: block;
    margin: 5px 0;
  }
  
  .scoreIcon {
    font-size: 16px;
    position: relative;
    display: block;
    float: left;
    margin: 4px 0px 0px 0;
  }
  
  .button {
    border-radius: 2px !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    margin: 0px !important;
  }
  
  .buttonDisabled {
    @extend .button;
    background-color: #d6d8e1 !important;
  }
  
  .buttonEnabled {
    @extend .button;
    background-color: #07c0ca !important;
  }
  
  button.buttonEnabled:hover {
    background-color: #006f7b !important;
    box-shadow: none !important;
  }
  
  .footer {
    font-size: 11px;
    margin-left: 5px;
    margin-right: 5px;
    letter-spacing: -0.2px;
    color: #787e9c;
    margin-top: 15px;
  
    .reviewed {
      line-height: normal;
      font-size: 11px;
      color: #787e9c;
  
      span {
        color: #00c3cc;
        cursor: pointer;
      }
  
      span:hover {
        color: #006f7b !important;
      }
    }
  
    .box {
      width: 30px;
      height: 30px;
      border-radius: 5px;
      border: solid 1.5px #07c0ca;
    }
  
    div.box:hover {
      border: solid 1.5px #08d2dd;
      box-shadow: 1px 1px 15px 0 rgba(120, 126, 156, 0.2);
    }
  }
  
  .message {
    font-size: 14px;
    line-height: 1.64;
    letter-spacing: -0.3px;
    text-align: center;
    color: #787e9c;
    padding: 15px;
  }