.background {
  margin: 30px 15px;
  width: 315px;
  height: 267px;
  background: #FFFFFF;
  box-shadow: 1px 1px 15px rgba(120, 126, 156, 0.2);
  border-radius: 8px;
  display:block;
}

.header {
  width: 255px;
  height: 22px;
  margin: 30px 30px 0px;
  display:inline-block;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 140% !important;

  text-align: center;
  letter-spacing: 0.27px;

  color: #31364C !important;
}

.text {
  width: 255px;
  height: 40px;
  margin: 15px 30px 0px;
  display:inline-block;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;

  text-align: center;

  color: #52576F;

  span {
    font-weight: 600;
    color: #07c0ca;
    cursor: pointer;
    text-indent: 1em;
  }

  span:before {
    content: "\00a0 ";
  }
}

.linkBankButton {
  width: 255px;
  height: 54px;
  display:inline-block;
  margin: 20px 30px 0px;
}

.errorBox {
  width: 315px;
  height: 74px;
  margin: 30px 15px;


  border: 1.3px solid #FE6E65;
  border-radius: 2px;

  img {
    float: left;
    margin: 20px;
  }

  div {
    margin: 20px 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 140% !important;
    color: #FE6E65;
    text-align: left;
  }

}
